import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  RegisterRequest,
  DeleteRequest,
  SortRequest
} from "@/api/schedule/request";
import {
  SearchResponse,
  DetailResponse,
  RegisterResponse
} from "@/api/schedule/response";
import { CommonResponse } from "@/api/response";

/**
 * アプリ内通知一覧検索APIをコールします。
 *
 * @param searchRequest アプリ内通知一覧検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-schedule", searchRequest);
  return response.data as SearchResponse;
}

/**
 * アプリ内通知設定取得APIをコールします。
 *
 * @param detailRequest アプリ内通知一覧検索のリクエストボディ
 * @return DeleteResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-schedule", detailRequest);
  return response.data as DetailResponse;
}

/**
 * アプリ内通知登録更新APIをコールします。
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-schedule", registerRequest);
  return response.data as RegisterResponse;
}

/**
 * アプリ内通知削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-schedule", deleteRequest);
  return response.data as CommonResponse;
}

/**
 * アプリ内通知ソート順更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/input-number-sort-schedule", sortRequest);
  return response.data as CommonResponse;
}
