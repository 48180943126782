import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import {
  SearchRequest,
  DeleteRequest,
  SortRequest
} from "@/api/schedule/request";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import ScheduleSearch, { ListItem } from "@/store/schedule/search";
import ScheduleDelete from "@/store/schedule/delete";
import ScheduleSort from "@/store/schedule/sort";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import WindowSizeChecker from "@/utils/window-size-checker";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker,
    UIDialogDelete
  }
})
export default class list extends Mixins(RedirectWithAlert){
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "アプリ内通知";
  headingSub = "App notification";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "アプリ内通知", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "app-notification" }
    },
    { text: "アプリ内通知一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "並び順", value: "sort", sortable: false },
    { text: "掲載期間", value: "viewDate", sortable: false },
    { text: "タイトル", value: "title", sortable: false },
    { text: "お知らせ/イベント", value: "kbn", sortable: false },
    { text: "状態", value: "viewFlg", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるスケジュールタイトル
  deletingScheduleName = null as string | null;

  // 削除されるスケジュールID
  deletingScheduleId = null as number | null;

  //並び順が変更されるスケジュールID
  sortingScheduleId = null as number | null;

  //変更されるスケジュールのソート番号
  sortingNumber = null as number | null;

  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    title: null as string | null,
    startDate: null as string | null,
    endDate: null as string | null,
    viewFlg: null as number | null
  };

  // 検索テーブルオプション
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return ScheduleSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return ScheduleSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return ScheduleSearch.getTotalCount;
  }

  /**
   * 幅がSPか否か
   */
  get isPhone() {
    return WindowSizeChecker.isPhone(this);
  }

  /**
   * 幅がタブレット以上か否か
   */
  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await ScheduleSearch.restore(this.inputOptions as SearchRequest);
    const request = ScheduleSearch.getSearchRequest;
    // 検索入力オプション
    request.title && (this.inputOptions.title = request.title);
    request.startDate && (this.inputOptions.startDate = request.startDate);
    request.endDate && (this.inputOptions.endDate = request.endDate);
    request.viewFlg && (this.inputOptions.viewFlg = request.viewFlg);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ScheduleSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "app-notification-edit",
      params: { id: String(item.id) },
      query: { shopId: this.id, shopName: this.shopName }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingScheduleId = item.id;
    this.deletingScheduleName = item.title;
  }

  /**
   * 掲載開始日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeFromCallback(date: string | null) {
    this.inputOptions.startDate = date;
  }

  /**
   * 掲載終了日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeToCallback(date: string | null) {
    this.inputOptions.endDate = date;
  }

  /**
   * テーブル行の並び順の値が変更された際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async sortChangeCallback(id: number, sortNumber: number) {
    if (this.isLoading) {
      return;
    }
    this.sortingScheduleId = id;
    this.sortingNumber = sortNumber;
    await ScheduleSort.sort(this.createSortRequest());
    if (ScheduleSort.isSuccess) {
      await Flash.clear();
      await this.search();
    } else {
      Flash.setErrorNow({
        message: ScheduleSort.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (!this.deletingScheduleId) {
      return;
    }

    this.isLoading = true;
    await ScheduleDelete.deleteOne({
      id: String(this.deletingScheduleId)
    } as DeleteRequest);
    if (ScheduleDelete.isSuccess) {
      await Flash.setSuccessNow({
        message: "アプリ内通知を削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: ScheduleDelete.getMessage,
        showReloadButton: true
      });
    }
    this.isLoading = false;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    await ScheduleSearch.search(this.createSearchRequest());
    if (!ScheduleSearch.isSuccess) {
      await Flash.setErrorNow({
        message: ScheduleSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async goToNew() {
    await this.$router.push({
      name: "app-notification-new",
      query: { shopId: this.id, shopName: this.shopName }
    });
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createSearchRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    request.shopId = Number(this.id);
    if (this.inputOptions.title) {
      request.title = this.inputOptions.title;
    } else {
      delete request.title;
    }

    if (this.inputOptions.startDate) {
      request.startDate = this.inputOptions.startDate;
    } else {
      delete request.startDate;
    }

    if (this.inputOptions.endDate) {
      request.endDate = this.inputOptions.endDate;
    } else {
      delete request.endDate;
    }

    if (this.inputOptions.viewFlg) {
      request.viewFlg = this.inputOptions.viewFlg;
    } else {
      delete request.viewFlg;
    }

    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSortRequest() {
    const request = { shopId: Number(this.id), id: this.sortingScheduleId, sort: this.sortingNumber } as SortRequest;
    return request;
  }
}
