import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/schedule/response";
import { SearchRequest } from "@/api/schedule/request";
import * as ScheduleAPI from "@/api/schedule";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "schedule/search";

/**
 * アプリ内通知一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get sort() {
    return this._item.sort;
  }
  get viewDate() {
    return `${this._item.viewDate} ~ ${this._item.viewDateEnd}`;
  }
  get title() {
    return this._item.title;
  }
  get kbn() {
    switch (this._item.kbn) {
      case 1:
        return "イベント";
      case 2:
        return "お知らせ";
      default:
        return "不明";
    }
  }
  get viewFlg() {
    switch (this._item.viewFlg) {
      case 0:
        return "非公開";
      case 1:
        return "公開";
      default:
        return "不明";
    }
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * アプリ内通知一覧検索API（/search-schedule）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.items.map(i => new ListItem(i)) || [];
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await ScheduleAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
